.contact__container {
    display: grid;
    place-content: center;
}

.contact__wrapper {
    width: fit-content;
    display: flex;
    margin: 1rem;
    gap: 2rem;
}

.contact__wrapper a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    border: 2px solid var(--color-primary);
    transition: var(--transition);
    border-radius: 50%;
    padding: .5rem;
}

.contact__wrapper a:hover {
    background: transparent;
    border-color: var(--bg-color);
}

/* Madia Queries (small screens) */
@media screen and (max-width:600px) {
    .contact__wrapper {
        gap: 1.5rem;
    }

    .contact__wrapper a {
        padding: .25rem;
        font-size: 1.2rem;
        border-radius: 1rem;
    }
}