.notfound__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20rem;
}

.notfound__container .btn {
    margin-top: 3rem;
}