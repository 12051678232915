.section-three {
    background-color: #222C2A;
    color: #fff;
}

.section-four {
    background-color: #824936;
    color: #fff;
}

.col {
    padding-top: 1.5rem;
}

.col_img img {
    width: 100%;
}

.col_p {
    align-self: start;
}

/* Madia Queries (small screens) */
@media (min-width:768px) {
    .about__col {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .intro__title {
        font-size: 3.75rem;
    }

    .section-title--dark {
        text-align: center;
    }
}